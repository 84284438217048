<template>
  <div class="setting-item s-exclude-holidays">
    <esmp-datepicker-adaptive
      v-model="dates"
      :placeholder="localSetting.name"
      is-multiple-mode
    />
  </div>
</template>

<script>
import dateFormat from '@/filters/dateFormat';
import convertStringToDate from '@/helpers/convertStringToDate';

export default {
  name: 'SExcludeHolidays',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    dates: {
      get() {
        const values = this.localSetting.value
          .replace('[', '')
          .replace(']', '')
          .replace(/\s+/g, '')
          .split(',')
          .map((value) => convertStringToDate(value, '.'));
        return this.localSetting.value ? values : null;
      },
      set(v) {
        const values = v.map((value) => dateFormat(value, 'DD.MM.YYYY'));
        this.localSetting.value = values.join(',') || '';
      },
    },
  },
};
</script>
